.new-section {
    padding: 55px 0 30px;
    background-color: #f5f5f5;
}

.new-dropdown {

    .new-dropdown-menu {
        padding: 5px 0 10px;
        background-color: #fff;
        box-shadow: 0 15px 25px rgba(0,0,0,0.1);
        border-radius: .5rem;
        min-width: 100% !important;
        opacity: 0;
        visibility: hidden;
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 100;
        transform: translateY(50px) scale(0.9);
        @include transition();


      
    }

    .dropdown-item {
        margin-right: 20px;
        font-size: 13px !important;
        white-space: normal !important;
    }

    &.is-dropdown-open {
        .new-tabs-box,
        .new-dropdown-menu {
            opacity: 1;
            visibility: visible;
            transform: translateY(15px) scale(1);
        }
      
    }
}

.new-form-control {
    border-bottom: 1px solid rgba(230,230,230,0.5) !important;
    margin-bottom: 10px;
}

.new-accordion-item {
    
    &.is-active {

        > .new-accordion-title {
            .new-accordion-icon {
                svg {
                    transform: rotate(180deg);
                }
          
            }
        }
    
    }
}

.new-accordion-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;

}

.new-accordion {
 
    .new-accordion {
        padding-left: 20px;
        padding-top: 5px;
    }
}

.new-accordion-icon {
    margin-right: 5px;
 
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    flex-shrink: 0;
    cursor: pointer;
    z-index: 2;
    pointer-events: all;

        svg {
            transition: all 0.3s ease-out;
            display: inline-flex;
            width: 14px;
        }
}

.new-mb-30 {
    margin-bottom: 30px;
}

.new-icon-box { 
    cursor: pointer;
}

.new-tabs {
    position: relative;
}

.new-tabs-content {
    display: none;
    opacity: 0;
    
    &.is-active {
        display: block;
        animation: fade-in 0.5s forwards;
    }
}

.new-tabs-box-top {
    border-bottom: 1px solid rgba(0,0,0,0.1) !important;
    padding-bottom: 15px;
    color: #000;
    margin-bottom: 20px;
    font-size: 15px;

}

.new-tabs-box-content {

    ul {
        list-style: none;
        column-gap: 60px;
        column-count: 3;
        padding-left: 0;

        @media only screen and (max-width: 700px) {
            column-gap: 30px;
            column-count: 2;
        }

        li {
        	page-break-inside: avoid;
            break-inside: avoid;
            overflow: hidden;
            padding-left: 0;

            > a  {
                display: inline-block;
                padding: 3px 0;
                font-size: 15px;
            }

            span {
                color: rgba(0,0,0,0.5);
            }

        }
    }
}

/*  */


@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


/* This is the core CSS of Tooltipster */

/* GENERAL STRUCTURE RULES (do not edit this section) */

.tooltipster-base {
	/* this ensures that a constrained height set by functionPosition,
	if greater that the natural height of the tooltip, will be enforced
	in browsers that support display:flex */
	display: flex;
	pointer-events: none;
	/* this may be overriden in JS for fixed position origins */
	position: absolute;
}

.tooltipster-box {
	/* see .tooltipster-base. flex-shrink 1 is only necessary for IE10-
	and flex-basis auto for IE11- (at least) */
	flex: 1 1 auto;
}

.tooltipster-content {
	/* prevents an overflow if the user adds padding to the div */
	box-sizing: border-box;
	/* these make sure we'll be able to detect any overflow */
	max-height: 100%;
	max-width: 100%;
	overflow: auto;
}

.tooltipster-ruler {
	/* these let us test the size of the tooltip without overflowing the window */
	bottom: 0;
	left: 0;
	overflow: hidden;
	position: fixed;
	right: 0;
	top: 0;
	visibility: hidden;
}

/* ANIMATIONS */

/* Open/close animations */

/* fade */

.tooltipster-fade {
	opacity: 0;
	-webkit-transition-property: opacity;
	-moz-transition-property: opacity;
	-o-transition-property: opacity;
	-ms-transition-property: opacity;
	transition-property: opacity;
}
.tooltipster-fade.tooltipster-show {
	opacity: 1;
}

/* grow */

.tooltipster-grow {
	-webkit-transform: scale(0,0);
	-moz-transform: scale(0,0);
	-o-transform: scale(0,0);
	-ms-transform: scale(0,0);
	transform: scale(0,0);
	-webkit-transition-property: -webkit-transform;
	-moz-transition-property: -moz-transform;
	-o-transition-property: -o-transform;
	-ms-transition-property: -ms-transform;
	transition-property: transform;
	-webkit-backface-visibility: hidden;
}
.tooltipster-grow.tooltipster-show {
	-webkit-transform: scale(1,1);
	-moz-transform: scale(1,1);
	-o-transform: scale(1,1);
	-ms-transform: scale(1,1);
	transform: scale(1,1);
	-webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
	-webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	-moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	-ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	-o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
}

/* swing */

.tooltipster-swing {
	opacity: 0;
	-webkit-transform: rotateZ(4deg);
	-moz-transform: rotateZ(4deg);
	-o-transform: rotateZ(4deg);
	-ms-transform: rotateZ(4deg);
	transform: rotateZ(4deg);
	-webkit-transition-property: -webkit-transform, opacity;
	-moz-transition-property: -moz-transform;
	-o-transition-property: -o-transform;
	-ms-transition-property: -ms-transform;
	transition-property: transform;
}
.tooltipster-swing.tooltipster-show {
	opacity: 1;
	-webkit-transform: rotateZ(0deg);
	-moz-transform: rotateZ(0deg);
	-o-transform: rotateZ(0deg);
	-ms-transform: rotateZ(0deg);
	transform: rotateZ(0deg);
	-webkit-transition-timing-function: cubic-bezier(0.230, 0.635, 0.495, 1);
	-webkit-transition-timing-function: cubic-bezier(0.230, 0.635, 0.495, 2.4);
	-moz-transition-timing-function: cubic-bezier(0.230, 0.635, 0.495, 2.4);
	-ms-transition-timing-function: cubic-bezier(0.230, 0.635, 0.495, 2.4);
	-o-transition-timing-function: cubic-bezier(0.230, 0.635, 0.495, 2.4);
	transition-timing-function: cubic-bezier(0.230, 0.635, 0.495, 2.4);
}

/* fall */

.tooltipster-fall {
	-webkit-transition-property: top;
	-moz-transition-property: top;
	-o-transition-property: top;
	-ms-transition-property: top;
	transition-property: top;
	-webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
	-webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	-moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	-ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	-o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
}
.tooltipster-fall.tooltipster-initial {
	top: 0 !important;
}
.tooltipster-fall.tooltipster-show {
}
.tooltipster-fall.tooltipster-dying {
	-webkit-transition-property: all;
	-moz-transition-property: all;
	-o-transition-property: all;
	-ms-transition-property: all;
	transition-property: all;
	top: 0 !important;
	opacity: 0;
}

/* slide */

.tooltipster-slide {
	-webkit-transition-property: left;
	-moz-transition-property: left;
	-o-transition-property: left;
	-ms-transition-property: left;
	transition-property: left;
	-webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
	-webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	-moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	-ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	-o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
}
.tooltipster-slide.tooltipster-initial {
	left: -40px !important;
}
.tooltipster-slide.tooltipster-show {
}
.tooltipster-slide.tooltipster-dying {
	-webkit-transition-property: all;
	-moz-transition-property: all;
	-o-transition-property: all;
	-ms-transition-property: all;
	transition-property: all;
	left: 0 !important;
	opacity: 0;
}

/* Update animations */

/* We use animations rather than transitions here because
 transition durations may be specified in the style tag due to
 animationDuration, and we try to avoid collisions and the use
 of !important */

/* fade */

@keyframes tooltipster-fading {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.tooltipster-update-fade {
	animation: tooltipster-fading 400ms;
}

/* rotate */

@keyframes tooltipster-rotating {
	25% {
		transform: rotate(-2deg);
	}
	75% {
		transform: rotate(2deg);
	}
	100% {
		transform: rotate(0);
	}
}

.tooltipster-update-rotate {
	animation: tooltipster-rotating 600ms;
}

/* scale */

@keyframes tooltipster-scaling {
	50% {
		transform: scale(1.1);
	}
	100% {
		transform: scale(1);
	}
}

.tooltipster-update-scale {
	animation: tooltipster-scaling 600ms;
}

/**
 * DEFAULT STYLE OF THE SIDETIP PLUGIN
 * 
 * All styles are "namespaced" with .tooltipster-sidetip to prevent
 * conflicts between plugins.
 */

/* .tooltipster-box */

.tooltipster-sidetip .tooltipster-box {
	background: #000;
	border: 2px solid black;
	border-radius: 4px;
}

.tooltipster-sidetip.tooltipster-bottom .tooltipster-box {
	margin-top: 8px;
}

.tooltipster-sidetip.tooltipster-left .tooltipster-box {
	margin-right: 8px;
}

.tooltipster-sidetip.tooltipster-right .tooltipster-box {
	margin-left: 8px;
}

.tooltipster-sidetip.tooltipster-top .tooltipster-box {
	margin-bottom: 8px;
}

/* .tooltipster-content */

.tooltipster-sidetip .tooltipster-content {
	color: white;
	line-height: 16px;
    font-size: 12px;
	padding: 12px 16px 14px;
    background-color: #000;
    text-align: justify;
}

/* .tooltipster-arrow : will keep only the zone of .tooltipster-arrow-uncropped that
corresponds to the arrow we want to display */

.tooltipster-sidetip .tooltipster-arrow {
	overflow: hidden;
	position: absolute;
}

.tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow {
	height: 10px;
	/* half the width, for centering */
	margin-left: -10px;
	top: 0;
	width: 20px;
}

.tooltipster-sidetip.tooltipster-left .tooltipster-arrow {
	height: 20px;
	margin-top: -10px;
	right: 0;
	/* top 0 to keep the arrow from overflowing .tooltipster-base when it has not
	been positioned yet */
	top: 0;
	width: 10px;
}

.tooltipster-sidetip.tooltipster-right .tooltipster-arrow {
	height: 20px;
	margin-top: -10px;
	left: 0;
	/* same as .tooltipster-left .tooltipster-arrow */
	top: 0;
	width: 10px;
}

.tooltipster-sidetip.tooltipster-top .tooltipster-arrow {
	bottom: 0;
	height: 10px;
	margin-left: -10px;
	width: 20px;
}

/* common rules between .tooltipster-arrow-background and .tooltipster-arrow-border */

.tooltipster-sidetip .tooltipster-arrow-background, .tooltipster-sidetip .tooltipster-arrow-border {
	height: 0;
	position: absolute;
	width: 0;
}

/* .tooltipster-arrow-background */

.tooltipster-sidetip .tooltipster-arrow-background {
	border: 10px solid transparent;
}

.tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow-background {
	border-bottom-color: #f5f5f5;
	left: 0;
	top: 3px;
}

.tooltipster-sidetip.tooltipster-left .tooltipster-arrow-background {
	border-left-color: #f5f5f5;
	left: -3px;
	top: 0;
}

.tooltipster-sidetip.tooltipster-right .tooltipster-arrow-background {
	border-right-color: #f5f5f5;
	left: 3px;
	top: 0;
}

.tooltipster-sidetip.tooltipster-top .tooltipster-arrow-background {
	border-top-color: #f5f5f5;
	left: 0;
	top: -3px;
}

/* .tooltipster-arrow-border */

.tooltipster-sidetip .tooltipster-arrow-border {
	border: 10px solid transparent;
	left: 0;
	top: 0;
} 

.tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow-border {
	border-bottom-color: #f5f5f5;
}

.tooltipster-sidetip.tooltipster-left .tooltipster-arrow-border {
	border-left-color: #f5f5f5;
}

.tooltipster-sidetip.tooltipster-right .tooltipster-arrow-border {
	border-right-color: #f5f5f5;
}

.tooltipster-sidetip.tooltipster-top .tooltipster-arrow-border {
	border-top-color: #f5f5f5;
}

/* tooltipster-arrow-uncropped */

.tooltipster-sidetip .tooltipster-arrow-uncropped {
	position: relative;
}

.tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow-uncropped {
	top: -10px;
}

.tooltipster-sidetip.tooltipster-right .tooltipster-arrow-uncropped {
	left: -10px;
}

.tooltip_templates {
   display: none; 


}

.tooltipster-content {
    padding: 30px 30px 10px !important;
    background-color: #f5f5f5 !important;

    a {
        text-decoration: none !important;
    }
}

.tooltipster-box {
    border-color: #f5f5f5 !important;

    
}